import React from 'react';
import RankBox from './RankBox';
import { LaborForceParticipationChart, MenWomenLaborForceParticipationChart } from './charts/LaborCharts';

const LaborView = () => {
  const laborRanks = [
    { title: "Job Growth", value: 1.2, type: 'percent', rank: 22 },
    { title: "Labor Force Participation", value: 68.4, type: 'percent', rank: 8 },
    { title: "Personal Income Growth", value: 3.1, type: 'percent', rank: 18 }
  ];

  return (
    <>
      <div className="flex flex-wrap justify-center mb-8">
        {laborRanks.map((item, index) => (
          <RankBox 
            key={index} 
            title={item.title} 
            value={item.value} 
            type={item.type} 
            rank={item.rank}
          />
        ))}
      </div>
      
      {/* Labor Force Participation Section */}
      <div className="bg-white p-4 rounded-lg shadow mb-8">
        <h2 className="text-xl font-semibold mb-4">Labor Force Participation</h2>
        {LaborForceParticipationChart()}
      </div>

      {/* Men vs Women Labor Force Participation Section */}
      <div className="bg-white p-4 rounded-lg shadow mb-8">
        <h2 className="text-xl font-semibold mb-4">Men vs Women Labor Force Participation</h2>
        {MenWomenLaborForceParticipationChart()}
      </div>

      {/* You can add more sections here as needed */}
    </>
  );
};

export default LaborView;