import React from 'react';
import Odometer from './Odometer';

const RankBox = ({ title, value, type, rank }) => {
  const getFormattedValue = () => {
    switch (type) {
      case 'rank':
        return <>#<Odometer value={value} format="d" /></>;
      case 'percent':
        return (
          <>
            <div>
              <Odometer value={value} format="(,ddd).d" />%
            </div>
            {rank && (
              <div className="text-sm font-normal mt-2">
                Rank: #{rank}
              </div>
            )}
          </>
        );
      default:
        return <Odometer value={value} format="d" />;
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 m-4 w-64 h-48 flex flex-col">
      <h2 className="text-xl mb-4 h-14 overflow-hidden text-center flex items-center justify-center">
        {title}
      </h2>
      <div className="flex-grow flex items-center justify-center">
        <div className="text-3xl font-bold text-center">
          {getFormattedValue()}
        </div>
      </div>
    </div>
  );
};

export default RankBox;
