import React from 'react';
import RankBox from './RankBox';

const EducationView = () => {
  const educationRanks = [
    { title: "K-12 Education Rank", value: 12, type: 'rank' },
    { title: "Higher Education Rank", value: 12, type: 'rank' },
    { title: "Education Spending Rank", value: 8, type: 'rank' }
  ];

  return (
    <>
      <div className="flex flex-wrap justify-center mb-8">
        {educationRanks.map((item, index) => (
          <RankBox key={index} title={item.title} rank={item.rank} />
        ))}
      </div>
      {/* Add more education-related content here */}
    </>
  );
};

export default EducationView;