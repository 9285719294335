import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import { scaleQuantile } from 'd3-scale';
import { Tooltip } from 'react-tooltip';

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const northeastStates = [
  "Connecticut", "Maine", "Massachusetts", "New Hampshire", 
  "Rhode Island", "Vermont", "New York", "New Jersey", "Pennsylvania"
];

// Sample data (replace with your actual data)
const gdpChangeData = [
  { state: "Connecticut", change: 2.5 },
  { state: "Maine", change: 1.8 },
  { state: "Massachusetts", change: 3.2 },
  { state: "New Hampshire", change: 2.1 },
  { state: "Rhode Island", change: 1.9 },
  { state: "Vermont", change: 1.5 },
  { state: "New York", change: 3.5 },
  { state: "New Jersey", change: 2.8 },
  { state: "Pennsylvania", change: 2.3 },
];

const NortheastGDPMap = () => {
    const [tooltipContent, setTooltipContent] = useState("");
  
    const colorScale = scaleQuantile()
      .domain(gdpChangeData.map(d => d.change))
      .range([
        "#e5f5e0",
        "#c7e9c0",
        "#a1d99b",
        "#74c476",
        "#41ab5d",
        "#238b45",
        "#006d2c"
      ]);
  
    const sortedData = [...gdpChangeData].sort((a, b) => b.change - a.change);
  
    return (
      <div className="flex flex-col md:flex-row justify-between items-start">
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          <ComposableMap projection="geoAlbersUsa">
            <ZoomableGroup center={[-74, 43]} zoom={3}>
              <Geographies geography={geoUrl}>
                {({ geographies }) => 
                  geographies
                    .filter(geo => northeastStates.includes(geo.properties.name))
                    .map(geo => {
                      const cur = gdpChangeData.find(s => s.state === geo.properties.name);
                      return (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          fill={cur ? colorScale(cur.change) : "#EEE"}
                          stroke="#FFF"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={`${geo.properties.name}: ${cur ? cur.change.toFixed(1) + '%' : 'N/A'}`}
                          style={{
                            hover: {
                              fill: "#F53",
                              outline: "none"
                            }
                          }}
                        />
                      );
                    })
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
          <Tooltip id="my-tooltip" />
        </div>
        <div className="w-full md:w-1/2 pl-0 md:pl-4 flex flex-col items-center">
          <h3 className="text-xl font-semibold mb-2">Q2 GDP Change Ranking</h3>
          <div className="inline-block text-left">
            <ol className="list-decimal list-inside" style={{ fontSize: '1.4rem' }}>
              {sortedData.map((item, index) => (
                <li key={item.state} className="mb-1">
                  {item.state}: {item.change.toFixed(1)}%
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    );
  };
  
  export default NortheastGDPMap;