import React, { useState } from 'react';
import { ButtonGroup, Button } from "@nextui-org/react";
import EconomyView from '../components/EconomyView';
import EducationView from '../components/EducationView';
import LaborView from '../components/LaborView';
import Layout from '../components/Layout';

const Dashboard = () => {
  const [activeView, setActiveView] = useState('economy');

  return (
    <Layout>
      <h1 className="text-3xl font-bold mb-8 text-center">Connecticut Dashboard</h1>
      
      <div className="mb-8 flex justify-center">
        <ButtonGroup>
          <Button 
            color={activeView === 'economy' ? "primary" : "default"}
            onClick={() => setActiveView('economy')}
          >
            Economy
          </Button>
          <Button 
            color={activeView === 'education' ? "primary" : "default"}
            onClick={() => setActiveView('education')}
          >
            Education
          </Button> 
          <Button 
            color={activeView === 'labor' ? "primary" : "default"}
            onClick={() => setActiveView('labor')}
          >
            Labor
          </Button>
        </ButtonGroup>
       
      </div>

      {activeView === 'economy' ? <EconomyView /> : activeView === 'education' ? <EducationView /> : <LaborView />}

      <div className="text-center text-gray-600 mt-8">
        <p>Rankings and chart data are based on the latest available information.</p>
        <p>Lower number indicates better ranking.</p>
      </div>
    </Layout>
  );
};

export default Dashboard;