import React from 'react';
import { ButtonGroup, Button } from '@nextui-org/react';

const YearSelector = ({ timeFrame, setTimeFrame }) => {
  return (
    <div className="mb-4 flex justify-center">
      <ButtonGroup>
        <Button 
          style={{
            backgroundColor: timeFrame === 1 ? '#0dc7ff' : 'transparent',
            borderColor: '#0dc7ff',
            color: timeFrame === 1 ? 'white' : '#0dc7ff'
          }}
          onClick={() => setTimeFrame(1)}
        >
          1 Year
        </Button>
        <Button 
          style={{
            backgroundColor: timeFrame === 5 ? '#0dc7ff' : 'transparent',
            borderColor: '#0dc7ff',
            color: timeFrame === 5 ? 'white' : '#0dc7ff'
          }}
          onClick={() => setTimeFrame(5)}
        >
          5 Years
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default YearSelector;