import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, NavbarBrand, NavbarContent, NavbarItem } from "@nextui-org/react";

const NavBar = () => {
  return (
    <Navbar className="bg-[#00a787] text-white">
      <NavbarBrand>
        {/* Replace with your actual logo */}
        <p className="font-bold text-2xl">Opportunity Connecticut</p>
      </NavbarBrand>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarItem>
          <Link to="/" className="text-white hover:text-gray-200">Home</Link>
        </NavbarItem>
        <NavbarItem>
          <Link to="/dashboard" className="text-white hover:text-gray-200">Dashboard</Link>
        </NavbarItem>
        <NavbarItem>
          <Link to="/research" className="text-white hover:text-gray-200">Research</Link>
        </NavbarItem>
        <NavbarItem>
          <Link to="/about" className="text-white hover:text-gray-200">About</Link>
        </NavbarItem>
        <NavbarItem>
          <Link to="/contact" className="text-white hover:text-gray-200">Contact</Link>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
};

export default NavBar;