import React from 'react';
import RankBox from './RankBox';
import { GDPBarChart, UnemploymentLineChart, GDPIndexChart } from './charts/EconomyCharts';
import NortheastGDPMap from './charts/NortheastGDPMap';

const EconomyView = () => {
    const economyRanks = [
      { title: "GDP Growth", value: 3.1, type: 'percent', rank: 15 },
      { title: "Job Growth", value: 1.2, type: 'percent', rank: 22 },
      { title: "Labor Force Participation", value: 68.4, type: 'percent', rank: 8 },
      { title: "Personal Income Growth", value: 3.1, type: 'percent', rank: 18 }
    ];
  
    return (
      <>
        <div className="flex flex-wrap justify-center mb-8">
          {economyRanks.map((item, index) => (
            <RankBox 
              key={index} 
              title={item.title} 
              value={item.value} 
              type={item.type} 
              rank={item.rank}
            />
          ))}
        </div>
        
        {/* Northeast GDP Map Section */}
        <div className="bg-white p-4 rounded-lg shadow mb-8">
          <h2 className="text-xl font-semibold mb-4">Northeast GDP Map</h2>
          <NortheastGDPMap />

        {/* GDP Index Section */}
        <div className="bg-white p-4 rounded-lg shadow mb-8">
          <h2 className="text-xl font-semibold mb-4">GDP Index</h2>
          <GDPIndexChart />
        </div>
        
        {/* GDP Section */}
        <div className="bg-white p-4 rounded-lg shadow mb-8">
          <h2 className="text-xl font-semibold mb-4">GDP Growth</h2>
          <GDPBarChart />
        </div>

        {/* Unemployment Section */}
        <div className="bg-white p-4 rounded-lg shadow mb-8">
          <h2 className="text-xl font-semibold mb-4">Unemployment Rate</h2>
          <UnemploymentLineChart />
        </div>




        </div>
      </>
    );
};

export default EconomyView;