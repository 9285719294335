import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { LineChart, Line } from 'recharts';
import { ButtonGroup, Button } from '@nextui-org/react';
import YearSelector from './YearSelector';

const gdpData = [
  { year: '2018', gdp: 275.7 },
  { year: '2019', gdp: 284.2 },
  { year: '2020', gdp: 273.6 },
  { year: '2021', gdp: 295.0 },
  { year: '2022', gdp: 308.1 },
];

const gdpQuarterlyData = [
  { quarter: '2018Q1', US: 20041.0, CT: 275.2 },
  { quarter: '2018Q2', US: 20289.9, CT: 276.8 },
  { quarter: '2018Q3', US: 20552.7, CT: 278.5 },
  { quarter: '2018Q4', US: 20679.7, CT: 279.9 },
  { quarter: '2019Q1', US: 20753.4, CT: 280.7 },
  { quarter: '2019Q2', US: 20998.7, CT: 282.3 },
  { quarter: '2019Q3', US: 21224.1, CT: 283.9 },
  { quarter: '2019Q4', US: 21481.4, CT: 285.6 },
  { quarter: '2020Q1', US: 21348.5, CT: 284.1 },
  { quarter: '2020Q2', US: 19371.1, CT: 268.5 },
  { quarter: '2020Q3', US: 21138.6, CT: 279.8 },
  { quarter: '2020Q4', US: 21477.6, CT: 283.2 },
  { quarter: '2021Q1', US: 22038.2, CT: 288.5 },
  { quarter: '2021Q2', US: 22740.4, CT: 294.1 },
  { quarter: '2021Q3', US: 23202.3, CT: 298.2 },
  { quarter: '2021Q4', US: 24008.5, CT: 303.7 },
  { quarter: '2022Q1', US: 24386.7, CT: 306.9 },
  { quarter: '2022Q2', US: 24882.9, CT: 310.2 },
  { quarter: '2022Q3', US: 25248.5, CT: 313.1 },
  { quarter: '2022Q4', US: 25723.9, CT: 316.5 },
];

const unemploymentData = [
  { month: 'Jan', rate: 3.8 },
  { month: 'Feb', rate: 3.7 },
  { month: 'Mar', rate: 3.6 },
  { month: 'Apr', rate: 3.5 },
  { month: 'May', rate: 3.6 },
  { month: 'Jun', rate: 3.7 },
];

export const GDPBarChart = () => {
  const [timeFrame, setTimeFrame] = useState(5);

  const filteredData = gdpData.slice(-timeFrame);

  return (
    <div>
      <YearSelector timeFrame={timeFrame} setTimeFrame={setTimeFrame} />
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={filteredData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="gdp" fill="#8884d8" name="GDP (Billion $)" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export const UnemploymentLineChart = () => (
  <ResponsiveContainer width="100%" height={300}>
    <LineChart data={unemploymentData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="rate" stroke="#82ca9d" name="Unemployment Rate (%)" />
    </LineChart>
  </ResponsiveContainer>
);

export const GDPIndexChart = () => {
  const [timeFrame, setTimeFrame] = useState(5);

  const indexedData = useMemo(() => {
    const startIndex = gdpQuarterlyData.length - (timeFrame * 4);
    const baseUS = gdpQuarterlyData[startIndex].US;
    const baseCT = gdpQuarterlyData[startIndex].CT;

    return gdpQuarterlyData.slice(startIndex).map(item => ({
      quarter: item.quarter,
      US: Number(((item.US / baseUS) * 100).toFixed(1)),
      CT: Number(((item.CT / baseCT) * 100).toFixed(1)),
    }));
  }, [timeFrame]);

  return (
    <div>
      <YearSelector timeFrame={timeFrame} setTimeFrame={setTimeFrame} />
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={indexedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="quarter" />
          <YAxis domain={[90, 'auto']} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="US" stroke="#8884d8" name="US GDP Index" />
          <Line type="monotone" dataKey="CT" stroke="#82ca9d" name="CT GDP Index" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};