import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { LineChart, Line } from 'recharts';
import { ButtonGroup, Button } from '@nextui-org/react';
import YearSelector from './YearSelector';

const laborForceParticipationDataUSCT = [
  { year: '2018', US: 68.4, CT: 68.4 },
  { year: '2019', US: 68.4, CT: 68.4 },
  { year: '2020', US: 68.4, CT: 68.4 },
  { year: '2021', US: 68.4, CT: 68.4 },
  { year: '2022', US: 68.4, CT: 68.4 },
];

const menWomenLaborForceParticipationData = [
    { year: 2010, men: 75.4, women: 58.4 },
    { year: 2011, men: 75.2, women: 58.6 },
    { year: 2012, men: 75.0, women: 58.8 },
    { year: 2013, men: 74.8, women: 59.0 },
    { year: 2014, men: 74.6, women: 59.2 },
    { year: 2015, men: 74.4, women: 59.4 },
    { year: 2016, men: 74.2, women: 59.6 },
    { year: 2017, men: 74.0, women: 59.8 },
    { year: 2018, men: 73.8, women: 60.0 },
    { year: 2019, men: 73.6, women: 60.2 },
    { year: 2020, men: 73.4, women: 60.4 },
    { year: 2021, men: 73.2, women: 60.6 },
    { year: 2022, men: 73.0, women: 60.8 },
];

export const LaborForceParticipationChart = () => {
    const [year, setYear] = useState(2018); 

    const data = useMemo(() => {
        return laborForceParticipationDataUSCT.map(item => ({
            ...item,
            year: item.year.toString()
        }));
    }, []);

    return (
        <ResponsiveContainer width="100%" height={350}>
            <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="US" fill="#8884d8" />
                <Bar dataKey="CT" fill="#82ca9d" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export const MenWomenLaborForceParticipationChart = () => {
    const [year, setYear] = useState(2018);

    const data = useMemo(() => {
        return menWomenLaborForceParticipationData.map(item => ({
            ...item,
            year: item.year.toString()
        }));
    }, []); 

    return (
        <ResponsiveContainer width="100%" height={350}>
            <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="men" fill="#8884d8" />
                <Bar dataKey="women" fill="#82ca9d" />
            </BarChart>
        </ResponsiveContainer>
    );
};

